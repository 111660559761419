import React, { useState, useEffect } from 'react';
import data from '../data/data-v2.json';
import ResultCard from './ResultCard';

const PlayLocationSelection = () => {
  const [playLocations, setPlayLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    setPlayLocations(data.playlocation); // Load play locations from JSON
  }, []);

  const handleFilter = () => {
    const selectedLocationIds = selectedLocations.map(Number);

    const filteredControllers = data.brands.map((brand) => {
      const controllersWithLocations = brand.controllers.filter((controller) =>
        controller.playlocation.some((location) => selectedLocationIds.includes(Number(location)))
      );

      if (controllersWithLocations.length > 0) {
        return {
          brandName: brand.brandname,
          controllers: controllersWithLocations.map((controller) => ({
            name: controller.title,
            description: controller.description,
            sizes: controller.size.map(
              (sizeId) =>
                data.size.find((size) => size.sizeid === parseInt(sizeId))?.name
            ),
            controllerstyle: controller.controllerstyle.map(
              (styleId) =>
                data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId))?.description
            ),
            playLocations: controller.playlocation.map(
              (locationId) =>
                data.playlocation.find((loc) => loc.playlocationid === parseInt(locationId))?.name
            ),
            sellers: controller.sellers,
          })),
        };
      }

      return null;
    }).filter(Boolean);

    setFilteredResults(filteredControllers);
  };

  const handleLocationChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedLocations(selected);
  };

  return (
    <div className="playlocation-selection">
      <h4 className="title">Play Location Selection</h4>

      <div className="filter-container">
        <label htmlFor="playlocation-filter">Select Play Locations:</label>
        <br />
        <select
          id="playlocation-filter"
          multiple
          value={selectedLocations}
          onChange={handleLocationChange}
          className="multi-select"
        >
          {playLocations.map((location) => (
            <option key={location.playlocationid} value={location.playlocationid}>
              {location.name}
            </option>
          ))}
        </select>
        <br />
        <button onClick={handleFilter} className="filter-button">
          Filter
        </button>
      </div>

      <div className="results-container">
        {filteredResults.length > 0 && (
          <div className="result-group">
            <h5>Controllers for Selected Play Locations</h5>
            {filteredResults.map((result, index) => (
              <ResultCard key={index} brand={result.brandName} controllers={result.controllers} />
            ))}
          </div>
        )}

        {filteredResults.length === 0 && (
          <p className="no-results">No results found. Please select play locations to filter.</p>
        )}
      </div>
    </div>
  );
};

export default PlayLocationSelection;
