import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppPage from './pages/AppPage';
import ControllerStylePage from './pages/ControllerStylePage';
import SwitchSelectionPage from './pages/SwitchSelectionPage';
import AdapterSelectionPage from './pages/AdapterSelectionPage';
import SizeSelectionPage from './pages/SizeSelectionPage';
import PlayLocationSelectionPage from './pages/PlayLocationSelectionPage';
import AccessoriesSelectionPage from './pages/AccessoriesSelectionPage';
import ButtonCountSelectionPage from './pages/ButtonCountSelectionPage';
import BrandSelectionPage from './pages/BrandSelectionPage';
// import AddControllerForm from './components/AddControllerForm';
import AddControllerPage from "./pages/AddControllerPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppPage />} />
        <Route path="/controller-style-selection" element={<ControllerStylePage />} />
        <Route path="/switch-selection" element={<SwitchSelectionPage />} />
        <Route path="/adapter-selection" element={<AdapterSelectionPage />} />
        <Route path="/size-selection" element={<SizeSelectionPage />} />
        <Route path="/playlocation-selection" element={<PlayLocationSelectionPage />} />
        <Route path="/accessories-selection" element={<AccessoriesSelectionPage />} />
        <Route path="/button-count-selection" element={<ButtonCountSelectionPage />} />
        <Route path="/brand-selection" element={<BrandSelectionPage />} />

        {/* <Route path="/add-controller" element={<AddControllerForm />} /> */}
        <Route path="/add-controller" element={<AddControllerPage />} />
      </Routes>
    </Router>
  );
}

export default App;



// import React from 'react';
// // import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import AppPage from './pages/AppPage';
// import ControllerStylePage from './pages/ControllerStylePage';
// import SwitchSelectionPage from './pages/SwitchSelectionPage';
// import AdapterSelectionPage from './pages/AdapterSelectionPage';
// import SizeSelectionPage from './pages/SizeSelectionPage';
// import PlayLocationSelectionPage from './pages/PlayLocationSelectionPage';
// import AccessoriesSelectionPage from './pages/AccessoriesSelectionPage';
// import ButtonCountSelectionPage from './pages/ButtonCountSelectionPage';

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<AppPage />} />
//         <Route path="/controller-style-selection" element={<ControllerStylePage />} />
//         <Route path="/switch-selection" element={<SwitchSelectionPage />} />
//         <Route path="/adapter-selection" element={<AdapterSelectionPage />} />
//         <Route path="/size-selection" element={<SizeSelectionPage />} />
//         <Route path="/playlocation-selection" element={<PlayLocationSelectionPage />} />
//         <Route path="/accessories-selection" element={<AccessoriesSelectionPage />} />
//         <Route path="/button-count-selection" component={<ButtonCountSelectionPage />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;


// import React from "react";
// // import AddControllerForm from "./components/AddControllerForm";
// // import AddControllerForm2 from "./components/AddControllerForm2";
// // import AddControllerForm3 from "./components/AddControllerForm3";
// // import AddControllerForm4 from "./components/AddControllerForm4";
// import AddControllerForm5 from "./components/AddControllerForm5";
// import ControllerStyleSelection from "./components/ControllerStyleSelection";

// function App() {
//   return (
//     <div>

//       <ControllerStyleSelection />

//       <hr />
//       <br />

//       <h1>Controller Entry Form</h1>
//       {/* <AddControllerForm /> */}
//       {/* <AddControllerForm2 /> */}
//       {/* <AddControllerForm3 /> */}
//       {/* <AddControllerForm4 /> */}
//       <AddControllerForm5 />
//     </div>
//   );
// }

// export default App;



// import React, { useState } from 'react';
// import Select from 'react-select';

// function App() {
//   const [controller, setController] = useState({
//     brand: '',
//     model: '',
//     size: [],
//     cost: '',
//     controllerStyle: [],
//     keycaps: [],
//     os: [],
//     buttons: [],
//     playLocation: [],
//     switches: [],
//     screen: '',
//     passthroughPort: '',
//     title: '',
//     description: '',
//     customArtTop: '',
//     customArtBottom: '',
//     artPaperSize: ''
//   });

//   const handleInputChange = (e) => {
//     setController({ ...controller, [e.target.name]: e.target.value });
//   };

//   const handleSelectChange = (name, value) => {
//     setController({ ...controller, [name]: value.map(v => v.value) });
//   };

//   const handleSubmit = () => {
//     console.log(JSON.stringify(controller, null, 2));
//     // Here you would typically send the data to your server or save it in a file
//   };

//   const sizeOptions = [
//     { value: 'mini', label: 'Mini' },
//     { value: 'XS', label: 'XS' },
//     { value: 'S', label: 'Small' },
//     { value: 'M', label: 'Medium' },
//     { value: 'L', label: 'Large' },
//     { value: 'XL', label: 'Extra Large' }
//   ];

//   const styleOptions = [
//     { value: 'acrylic', label: 'Acrylic' },
//     { value: 'matte-acrylic', label: 'Matte-Acrylic' },
//     { value: '3D printed', label: '3D Printed' },
//     // Add more options as needed
//   ];

//   const osOptions = [
//     { value: 'gp2040-ce', label: 'gp2040-ce' },
//     { value: 'rp2040', label: 'rp2040' }
//   ];

//   return (
//     <div>
//       <input
//         name="brand"
//         value={controller.brand}
//         onChange={handleInputChange}
//         placeholder="Brand"
//       />
//       <input
//         name="model"
//         value={controller.model}
//         onChange={handleInputChange}
//         placeholder="Model"
//       />
//       <Select
//         isMulti
//         options={sizeOptions}
//         onChange={(value) => handleSelectChange('size', value)}
//         placeholder="Select Size"
//       />
//       <Select
//         isMulti
//         options={styleOptions}
//         onChange={(value) => handleSelectChange('controllerStyle', value)}
//         placeholder="Select Controller Style"
//       />
//       <Select
//         isMulti
//         options={osOptions}
//         onChange={(value) => handleSelectChange('os', value)}
//         placeholder="Select OS"
//       />
//       <button onClick={handleSubmit}>Save</button>
//     </div>
//   );
// }

// export default App;

















// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
