import React from 'react';

const ResultCard = ({ brand, controllers }) => {
    return (
        <div className="result-card">
            <h5 className="brand-name">{brand}</h5>
            <ul className="controller-list">
                {controllers.map((controller, index) => (
                    <li key={index}>
                        <strong>{controller.name}</strong>: {controller.description}

                        {controller.costs && (
                            <p><strong>Cost Options:</strong> ${controller.costs.join(', $')}</p>
                        )}
                        
                        {controller.sizes && controller.sizes.length > 0 && (
                            <p><strong>Sizes:</strong> {controller.sizes.join(', ')}</p>
                        )}

                        {controller.buttons && controller.buttons.length > 0 && (
                            <p><strong>Button Options:</strong> {controller.buttons.join(', ')}</p>
                        )}

                        {controller.controllerstyle && controller.controllerstyle.length > 0 && (
                            <p><strong>Controller Style:</strong> {controller.controllerstyle.join(', ')}</p>
                        )}

                        {controller.playLocations && controller.playLocations.length > 0 && (
                            <p><strong>Play Locations:</strong> {controller.playLocations.join(', ')}</p>
                        )}

                        {controller.accessories && controller.accessories.length > 0 && (
                            <p><strong>Accessories:</strong> {controller.accessories.join(', ')}</p>
                        )}

                        {controller.sellers && controller.sellers.length > 0 && (
                            <div className="links">
                                <strong>Buy Links:</strong>
                                <ul>
                                    {controller.sellers.map((seller, sellerIndex) => (
                                        <li key={sellerIndex}>
                                            <a href={seller.link} target="_blank" rel="noopener noreferrer">
                                                {seller.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ResultCard;
