import React from 'react';
import BrandSelection from '../components/BrandSelection';
import '../pagestyles/BrandSelectionPage.scss';
import Footer from '../components/Footer';

const BrandSelectionPage = () => {
    return (
        <div className="brand-selection-page">
            <BrandSelection />
            <Footer />
        </div>
    );
};

export default BrandSelectionPage;
