import React, { useState, useEffect } from 'react';
import data from '../data/data-v2.json';
import ResultCard from './ResultCard';

const AccessoriesSelection = () => {
  const [accessories, setAccessories] = useState([]);
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    setAccessories(data.accessories); // Load accessories from JSON
  }, []);

  const handleFilter = () => {
    const selectedAccessoryIds = selectedAccessories.map(Number);

    const filteredControllers = data.brands.map((brand) => {
      const controllersWithAccessories = brand.controllers.filter((controller) =>
        controller.accessories.some((accessory) => selectedAccessoryIds.includes(Number(accessory)))
      );

      if (controllersWithAccessories.length > 0) {
        return {
          brandName: brand.brandname,
          controllers: controllersWithAccessories.map((controller) => ({
            name: controller.title,
            description: controller.description,
            sizes: controller.size.map(
              (sizeId) =>
                data.size.find((size) => size.sizeid === parseInt(sizeId))?.name
            ),
            playLocations: controller.playlocation.map(
              (locationId) =>
                data.playlocation.find((loc) => loc.playlocationid === parseInt(locationId))?.name
            ),
            accessories: controller.accessories.map(
              (accessoryId) =>
                data.accessories.find((acc) => acc.accessoriesid === parseInt(accessoryId))?.name
            ),
            buttons: controller.buttons,
            controllerstyle: controller.controllerstyle.map(
            (styleId) =>
                data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId))?.description
            ),
              
            sellers: controller.sellers,
          })),
        };
      }
      return null;
    }).filter(Boolean);

    setFilteredResults(filteredControllers);
  };

  const handleAccessoryChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedAccessories(selected);
  };

  return (
    <div className="accessories-selection">
      <h4 className="title">Accessories Selection</h4>

      <div className="filter-container">
        <label htmlFor="accessory-filter">Select Accessories:</label>
        <select
          id="accessory-filter"
          multiple
          value={selectedAccessories}
          onChange={handleAccessoryChange}
          className="multi-select"
        >
          {accessories.map((accessory) => (
            <option key={accessory.accessoriesid} value={accessory.accessoriesid}>
              {accessory.name}
            </option>
          ))}
        </select>
        <button onClick={handleFilter} className="filter-button">
          Filter
        </button>
      </div>

      <div className="results-container">
        {filteredResults.length > 0 && (
          <div className="result-group">
            <h5>Controllers for Selected Accessories</h5>
            {filteredResults.map((result, index) => (
              <ResultCard key={index} brand={result.brandName} controllers={result.controllers} />
            ))}
          </div>
        )}

        {filteredResults.length === 0 && (
          <p className="no-results">No results found. Please select accessories to filter.</p>
        )}
      </div>




<hr />
<hr />
<hr />
<hr />

        <h4>Accessories Options</h4>
        <ul>
        {accessories.map((accessoriesItem, index) => (
            <li key={index}>
            <strong>{accessoriesItem.name}</strong>: {accessoriesItem.description}
            {accessoriesItem.link ? (
                <p>
                <a href={accessoriesItem.link} target="_blank" rel="noopener noreferrer">
                    Buy
                </a>
                </p>
            ) : (
                <p>No link available</p>
            )}
            </li>
        ))}
        </ul>

      
    </div>
  );
};

export default AccessoriesSelection;
