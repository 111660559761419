import React, { useState, useEffect } from 'react';
import data from '../data/data-v2.json';
import ResultCard from './ResultCard';

const BrandSelection = () => {
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        // Load brands from JSON
        setBrands(data.brands);
    }, []);

    const handleFilter = () => {
        if (selectedBrands.length === 0) return;

        const filteredControllers = brands
            .filter((brand) => selectedBrands.includes(brand.brandname))
            .map((brand) => ({
                brandName: brand.brandname,
                controllers: brand.controllers.map((controller) => ({
                    name: controller.title,
                    description: controller.description,
                    sizes: controller.size.map(
                        (sizeId) =>
                            data.size.find((size) => size.sizeid === parseInt(sizeId))?.name
                    ),
                    buttons: controller.buttoncountoptions,
                    costs: controller.cost,
                    controllerstyle: controller.controllerstyle.map(
                        (styleId) =>
                            data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId))?.description
                    ),
                    playLocations: controller.playlocation.map(
                        (locationId) =>
                            data.playlocation.find((loc) => loc.playlocationid === parseInt(locationId))?.name
                    ),
                    accessories: controller.accessories.map(
                        (accessoryId) =>
                            data.accessories.find((acc) => acc.accessoriesid === parseInt(accessoryId))?.name
                    ),
                    sellers: controller.sellers,
                })),
            }));

        setFilteredResults(filteredControllers);
    };

    const handleBrandChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(options[i].value);
            }
        }
        setSelectedBrands(selected);
    };

    return (
        <div className="brand-selection">
            <h4 className="title">Brand Selection</h4>

            <div className="filter-container">
                <label htmlFor="brand-filter">Select Brands:</label>
                <br />
                <select
                    id="brand-filter"
                    multiple
                    value={selectedBrands}
                    onChange={handleBrandChange}
                    className="multi-select"
                >
                    {brands.map((brand) => (
                        <option key={brand.brandname} value={brand.brandname}>
                            {brand.brandname}
                        </option>
                    ))}
                </select>
                <br />
                <button onClick={handleFilter} className="filter-button">
                    Filter
                </button>
            </div>

            <div className="results-container">
                {filteredResults.length > 0 ? (
                    filteredResults.map((result, index) => (
                        <ResultCard key={index} brand={result.brandName} controllers={result.controllers} />
                    ))
                ) : (
                    <p className="no-results">No results found. Please select brands to filter.</p>
                )}
            </div>
        </div>
    );
};

export default BrandSelection;
