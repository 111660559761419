import React from 'react';
import ControllerStyleSelection from '../components/ControllerStyleSelection';
import '../pagestyles/ControllerStylePage.scss';
import Footer from '../components/Footer';

const ControllerStylePage = () => {
  return (
    <div className="controller-style-page">
      <ControllerStyleSelection />
      <Footer />
    </div>
  );
};

export default ControllerStylePage;
