import React, { useState, useEffect } from "react";
// import data1 from '../data/data-v2.json';

const AddControllerForm = () => {
  const [controllerData, setControllerData] = useState({
    controllerid: 0,
    brand: 0,
    title: "",
    description: "",
    cost: [],
    models: [],
    comments: "",
    size: [],
    screen: 1,
    passthroughport: 1,
    powerports: 1,
    powerporttype: 2,

    dimensions: "200mmx196mmx12mm", 
    customarttop: 1, 
    customartbottom: 1, 
    artpapersize: "A5  | 8.5x11",

    controllerstyle: [],
    stockswitches: [],
    switchesupgrades: [],
    keycaps: [],
    os: [],
    playlocation: [],
    buttoncountoptions: [],
    supportlinks: [{ link: "", notes: "" }],
    similarcontroller: [{ brandid: 0, controllerid: 0, comments: "", similarfingerspacing: 1 }],
    sellers: [{ name: "", link: "" }],
    images: [{ link: "" }],
    adapters: [],
    accessories: [],
  });

  const [jsonData, setJsonData] = useState(null);
  const [brands, setBrands] = useState([]);
  const [controllers, setControllers] = useState([]);
  // const [selectedBrand, setSelectedBrand] = useState('');
  const [switches, setSwitches] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [keycapsOptions, setKeycapsOptions] = useState([]);
  const [controllerStyleOptions, setControllerStyleOptions] = useState([]);
  const [adaptersOptions, setAdaptersOptions] = useState([]);
  const [accessoriesOptions, setAccessoriesOptions] = useState([]);
  const [osOptions, setOsOptions] = useState([]);
  const [playLocationOptions, setPlayLocationOptions] = useState([]);

  // const [newSimilarController, setNewSimilarController] = useState({
  //   brandid: -1,
  //   controllerid: -1,
  //   comments: '',
  //   similarfingerspacing: 1
  // });
  
  // useEffect(() => {
  //   // fetch("../data/data-v2.json")
  //   //   .then(response => {
  //   //     if (!response.ok) {
  //   //       throw new Error('Network response was not ok ' + response.statusText);
  //   //     }
  //   //     return response.json();
  //   //   })
  //   //   .then(data => setJsonData(data))
  //   //   .catch(error => console.error("Failed to load JSON data", error));


  //     // console.log("Fetching JSON...");
  //     fetch("data-v2.json")
  //     // fetch("..data/data-v2.json")
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok ' + response.statusText);
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         // console.log("Fetched JSON Data:", data); // Debug log
  //         setJsonData(data);
  //         setBrands(data.brands);
  //         // setControllers(data.brands.flatMap(brand => brand.controllers));
  //         setControllers(data.brands.flatMap(brand => brand.controllers.map(controller => ({ ...controller, brandid: parseInt(brand.brandid)}))));

          
  //         // const allControllers = data.brands.reduce((acc, brand) => {
  //         //   return acc.concat(brand.controllers);
  //         // }, []);
  //         // setControllers(allControllers);



  //         // Calculate the maximum controller ID
  //         const maxControllerId = allControllers.reduce((max, controller) => {
  //           return controller.controllerid > max ? controller.controllerid : max;
  //         }, 0);

  //         // Set initial controller ID to maxControllerId + 1
  //         setControllerData(prevState => ({
  //           ...prevState,
  //           controllerid: maxControllerId + 1
  //         }));


  //       })
  //       .catch(error => console.error("Failed to load JSON data", error));
          


  // }, []);


  // useEffect(() => {
  //   fetch("data-v2.json")  // Adjust the path as necessary
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok: ' + response.statusText);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       setJsonData(data);
  //       setBrands(data.brands);
  
  //       // Map controllers with the brand ID included
  //       const allControllers = data.brands.flatMap(brand =>
  //         brand.controllers.map(controller => ({
  //           ...controller,
  //           brandid: parseInt(brand.brandid),
  //           controllerid: parseInt(controller.controllerid)
  //         }))
  //       );
  //       setControllers(allControllers);
  
  //       // Calculate the maximum controller ID
  //       const maxControllerId = allControllers.reduce((max, controller) => {
  //         return controller.controllerid > max ? controller.controllerid : max;
  //       }, 0);
  
  //       // Set initial controller ID to maxControllerId + 1
  //       setControllerData(prevState => ({
  //         ...prevState,
  //         controllerid: maxControllerId + 1
  //       }));
  //     })
  //     .catch(error => {
  //       console.error("Failed to load JSON data", error);
  //     });
  // }, []);
  

  // useEffect(() => {
  //   fetch("data-v2.json")  // Adjust the path as necessary
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok: ' + response.statusText);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       setJsonData(data);
  //       setBrands(data.brands);
        
  //       // Map controllers with the brand ID included
  //       const allControllers = data.brands.flatMap(brand =>
  //         brand.controllers.map(controller => ({
  //           ...controller,
  //           brandid: parseInt(brand.brandid),
  //           controllerid: parseInt(controller.controllerid)
  //         }))
  //       ).sort((a, b) => a.title.localeCompare(b.title));  // Sorting controllers alphabetically by title
  //       setControllers(allControllers);
    
  //       // Calculate the maximum controller ID
  //       const maxControllerId = allControllers.reduce((max, controller) => {
  //         return controller.controllerid > max ? controller.controllerid : max;
  //       }, 0);
    
  //       // Set initial controller ID to maxControllerId + 1
  //       setControllerData(prevState => ({
  //         ...prevState,
  //         controllerid: maxControllerId + 1
  //       }));
  
  //       // Assuming your switches are at the top level of the JSON structure
  //       setSwitches(data.switches); // Set state for switches
  //     })
  //     .catch(error => {
  //       console.error("Failed to load JSON data", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   fetch("data-v2.json")  // Adjust the path as necessary
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok: ' + response.statusText);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       setJsonData(data);
  //       setBrands(data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname)));
  
  //       // Map controllers with the brand ID included and sort them
  //       const allControllers = data.brands.flatMap(brand =>
  //         brand.controllers.map(controller => ({
  //           ...controller,
  //           brandid: parseInt(brand.brandid),
  //           controllerid: parseInt(controller.controllerid)
  //         }))
  //       ).sort((a, b) => a.title.localeCompare(b.title));  // Sorting controllers alphabetically by title
  //       setControllers(allControllers);
  
  //       // Calculate the maximum controller ID
  //       const maxControllerId = allControllers.reduce((max, controller) => {
  //         return controller.controllerid > max ? controller.controllerid : max;
  //       }, 0);
  
  //       // Set initial controller ID to maxControllerId + 1
  //       setControllerData(prevState => ({
  //         ...prevState,
  //         controllerid: maxControllerId + 1
  //       }));
  
  //       // Assuming your switches are at the top level of the JSON structure
  //       // Sort switches alphabetically by description
  //       const sortedSwitches = data.switches.sort((a, b) => a.description.localeCompare(b.description));
  //       setSwitches(sortedSwitches);
  //     })
  //     .catch(error => {
  //       console.error("Failed to load JSON data", error);
  //     });
  // }, []);


  // useEffect(() => {
  //   fetch("data-v2.json")  // Adjust the path as necessary
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok: ' + response.statusText);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       setJsonData(data);
  //       setBrands(data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname)));
  
  //       // Map and sort controllers
  //       const allControllers = data.brands.flatMap(brand =>
  //         brand.controllers.map(controller => ({
  //           ...controller,
  //           brandid: parseInt(brand.brandid),
  //           controllerid: parseInt(controller.controllerid)
  //         }))
  //       ).sort((a, b) => a.title.localeCompare(b.title));
  //       setControllers(allControllers);
  
  //       // Set initial controller ID to the highest found + 1
  //       const maxControllerId = allControllers.reduce((max, controller) => controller.controllerid > max ? controller.controllerid : max, 0);
  //       setControllerData(prevState => ({
  //         ...prevState,
  //         controllerid: maxControllerId + 1
  //       }));
  
  //       // Sort and set switches, sizes, keycaps, and styles
  //       setSwitches(data.switches.sort((a, b) => a.description.localeCompare(b.description)));
  //       setSizeOptions(data.sizes.sort((a, b) => a.description.localeCompare(b.description))); // Ensure 'sizes' exists in your JSON
  //       setKeycapsOptions(data.keycaps.sort((a, b) => a.description.localeCompare(b.description))); // Ensure 'keycaps' exists in your JSON
  //       setControllerStyleOptions(data.controllerStyles.sort((a, b) => a.description.localeCompare(b.description))); // Ensure 'controllerStyles' exists in your JSON
  //     })
  //     .catch(error => {
  //       console.error("Failed to load JSON data", error);
  //     });
  // }, []);


  useEffect(() => {
    fetch("data-v2.json")  // Adjust the path as necessary
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setJsonData(data);
        setBrands(data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname)));
  
        // Map and sort controllers
        const allControllers = data.brands.flatMap(brand =>
          brand.controllers.map(controller => ({
            ...controller,
            brandid: parseInt(brand.brandid),
            controllerid: parseInt(controller.controllerid)
          }))
        ).sort((a, b) => a.title.localeCompare(b.title));
        setControllers(allControllers);
  
        // Set initial controller ID to the highest found + 1
        const maxControllerId = allControllers.reduce((max, controller) => controller.controllerid > max ? controller.controllerid : max, 0);
        setControllerData(prevState => ({
          ...prevState,
          controllerid: maxControllerId + 1
        }));
  
        // Sort and set switches, sizes, keycaps, and styles
        setSwitches(data.switches.sort((a, b) => a.description.localeCompare(b.description)));
        setSizeOptions(data.size.sort((a, b) => a.description.localeCompare(b.description)));
        setKeycapsOptions(data.keycaps.sort((a, b) => a.description.localeCompare(b.description)));
        setControllerStyleOptions(data.controllerstyle.sort((a, b) => a.description.localeCompare(b.description)));
        setOsOptions(data.os.sort((a, b) => a.name.localeCompare(b.name)));
        setPlayLocationOptions(data.playlocation.sort((a, b) => a.name.localeCompare(b.name)));

        // setAdaptersOptions(data.adapters.sort((a, b) => a.description.localeCompare(b.description)));
        // setAccessoriesOptions(data.accessories.sort((a, b) => a.description.localeCompare(b.description)));
        setAdaptersOptions(data.adapters.sort((a, b) => a.name.localeCompare(b.name)));
        setAccessoriesOptions(data.accessories.sort((a, b) => a.name.localeCompare(b.name)));
     
      })
      .catch(error => {
        console.error("Failed to load JSON data", error);
      });
  }, []);

  
  const handleInputChange = (field, value) => {
    setControllerData({ ...controllerData, [field]: value });
  };

  const handleInputChangeArray = (field, value) => {
    if (field === "cost") {
      const costsArray = value.split(",").map(cost => cost.trim()); // Split by commas and trim spaces
      setControllerData({ ...controllerData, cost: costsArray });
    } else if (field === "models") {
      const modelssArray = value.split(",").map(models => models.trim()); // Split by commas and trim spaces
      setControllerData({ ...controllerData, models: modelssArray });
    } else {
      setControllerData({ ...controllerData, [field]: value });
    }
  };  

  const handleMultiSelectChange = (field, values) => {
    setControllerData({ ...controllerData, [field]: values });
  };



  const handleAddSupportLink = () => {
    const lastSupportLink = controllerData.supportlinks[controllerData.supportlinks.length - 1];
    if (lastSupportLink.link !== "" || lastSupportLink.notes !== "") {
      setControllerData({
        ...controllerData,
        supportlinks: [...controllerData.supportlinks, { link: "", notes: "" }]
      });
    }
  };
// link



const handleAddSimilarController = () => {
  const lastSimilarController = controllerData.similarcontroller[controllerData.similarcontroller.length - 1];

  // Check if the last similar controller has sufficiently filled details before adding a new one
  if (
      lastSimilarController.brandid !== 0 && 
      lastSimilarController.controllerid !== 0 && 
      // lastSimilarController.comments !== "" && 
      lastSimilarController.similarfingerspacing !== ""
  ) {
      // All fields are filled, add a new default entry
      setControllerData(prevState => ({
          ...prevState,
          similarcontroller: [...prevState.similarcontroller, { brandid: 0, controllerid: 0, comments: '', similarfingerspacing: 1 }]
      }));
  } else {
      // alert("Please complete all fields of the existing similar controller before adding a new one.");
  }
};


  const handleChangeSimilarController = (index, field, value) => {
    const updatedList = [...controllerData.similarcontroller];
    if (field === 'brandid' || field === 'controllerid') {
      value = parseInt(value, 10);
    }
    updatedList[index][field] = value;
    setControllerData({ ...controllerData, similarcontroller: updatedList });
  };

  // const renderControllerOptions2a = (brandid) => {
  //   return controllers.filter(c => c.brandid === parseInt(brandid)).map(controller => (
  //     <option key={controller.controllerid} value={controller.controllerid}>{controller.title}</option>
  //   ));
  // };


  // const renderControllerOptions2b = (brandid) => {
  //   const brand = brands.find(b => b.brandid.toString() === brandid);
  //   return brand ? brand.controllers.map(controller => (
  //     <option key={controller.controllerid} value={controller.controllerid}>
  //       {controller.title}
  //     </option>
  //   )) : null;
  // };
  
  const renderControllerOptions2c = (brandid) => {
    // console.log("-Current Brand ID:", brandid);
    // console.log("--Controllers:", controllers);
    const filteredControllers = controllers.filter(c => c.brandid === parseInt(brandid));
    // console.log("---Filtered Controllers:", filteredControllers);
    return filteredControllers.map(controller => (
      <option key={controller.controllerid} value={controller.controllerid}>{controller.title}</option>
    ));
  };
  



  const handleAddSeller = () => {
    const lastSeller = controllerData.sellers[controllerData.sellers.length - 1];
    if (lastSeller && (lastSeller.name !== "" || lastSeller.link !== "")) {
        setControllerData({
            ...controllerData,
            sellers: [...controllerData.sellers, { name: "", link: "" }]
        });
    }
  };

  const handleAddImage = () => {
    const lastImage = controllerData.images[controllerData.images.length - 1];
    if (lastImage && lastImage.link !== "") {
        setControllerData({
            ...controllerData,
            images: [...controllerData.images, { link: "" }]
        });
    }
  };

  



  // const handleAddField = (arrayName, initialValues) => {
  //   setControllerData(prev => ({
  //     ...prev,
  //     [arrayName]: [...prev[arrayName], initialValues]
  //   }));
  // };
  
  
  
  const handleBrandChange = (event) => {
    const brandId = event.target.value;
    // setSelectedBrand(brandId);
    setControllerData(prevState => ({
      ...prevState,
      brand: parseInt(brandId, 10)
  }));
  };

  
  // const handleBrandChange = (event) => {
  //   const brandId = event.target.value;
  //   setSelectedBrand(brandId);
  //   setControllerData({...controllerData, brand: brandId});
  // };

  //   const handleBrandChange = (event) => {
  //     const brandId = event.target.value;
  //     console.log("Selected brand ID:", brandId); // This should log the correct brand ID from the dropdown
  //     setSelectedBrand(brandId);

  //     // // Find the brand based on the selected brandId
  //     // const brand = brands.find(b => b.brandid.toString() === brandId);
  //     // if (brand && brand.controllers.length > 0) {
  //     //     // Find the maximum controller ID
  //     //     const maxControllerId = Math.max(...brand.controllers.map(controller => controller.controllerid));
  //     //     // Set the new controller ID to maxControllerId + 1
  //     //     setControllerData(prevState => ({
  //     //         ...prevState,
  //     //         controllerid: maxControllerId + 1,
  //     //         brand: parseInt(brandId, 10) // Ensure brand is always stored as a number
  //     //     }));
  //     // } else {
  //     //     // Reset the controllerid to 1 if no controllers are found or brand is new
  //     //     setControllerData(prevState => ({
  //     //         ...prevState,
  //     //         controllerid: 1,
  //     //         brand: parseInt(brandId, 10)
  //     //     }));
  //     // }
  //   };


  // // Render controllers based on the selected brand
  // const renderControllerOptions = () => {
  //   const brand = brands.find(b => b.brandid.toString() === selectedBrand);
  //   return brand ? brand.controllers.map(controller => (
  //     <option key={controller.controllerid} value={controller.controllerid}>
  //       {controller.title}
  //     </option>
  //   )) : null;
  // };

  
  const handleCopyToClipboard = () => {
    if (jsonData) {
      navigator.clipboard
        .writeText(JSON.stringify(jsonData, null, 2))
        .then(() => {
          alert("JSON data copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy JSON data:", err);
          alert("Failed to copy JSON data. Please try again.");
        });
    }
  };


  

  const handleSave = () => {
    const newData = { ...controllerData };

    // Filter out empty entries
    newData.supportlinks = newData.supportlinks.filter(
      (supportlink) => 
        supportlink.link !== ""
       || supportlink.notes !== ""
    );
    newData.sellers = newData.sellers.filter(
      (seller) => seller.name !== "" || seller.link !== ""
    );
    newData.similarcontroller = newData.similarcontroller.filter(entry => 
      entry.controllerid !== 0 && 
      entry.brandid !== 0 && 
      // entry.comments !== "" &&
      entry.similarfingerspacing !== ""
    );
    newData.images = newData.images.filter((image) => image.link !== "");

    // Convert fields to integers
    const fieldsToInt = [
      "controllerid",
      "brand",
      "screen",
      "passthroughport",
      "customarttop",
      "customartbottom",
      "powerports",
      "powerporttype",
    ];
  
    fieldsToInt.forEach((field) => {
      newData[field] = parseInt(newData[field], 10);
    });
  
    // Convert button count options to integers
    if (newData.buttoncountoptions) {
      newData.buttoncountoptions = newData.buttoncountoptions.map((option) =>
        parseInt(option, 10)
      );
    }
  
    const jsonData = JSON.stringify(newData, null, 2);
  
    // Save to clipboard
    navigator.clipboard
      .writeText(jsonData)
      .then(() => {
        alert("Controller data copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy data:", err);
        alert("Failed to copy data to clipboard.");
      });
  
    console.log("Saved Data:", jsonData);

  };
  

  // console.log(brands);
  // console.log(controllers);

  return (
    <div>

      <form>

      <label>
        Brand:
        <br />
{/* 
        <select
            style={{width: "100%"}}
            value={controllerData.brand}
            onChange={handleBrandChange}
        >
          <option value="">Select Brand</option>

            {brands.map(brand => (
                <option key={brand.brandid} value={brand.brandid}>
                    {brand.brandname}
                </option>
            ))}
        </select>
 */}

        <select
          style={{width: "100%"}}
          value={controllerData.brand}
          onChange={handleBrandChange}
        >
          <option value="">Select Brand</option>
          {brands.map(brand => (
              <option key={brand.brandid} value={brand.brandid}>
                  {brand.brandname}
              </option>
          ))}
        </select>


        </label>
        <br />

      <label>
          Controller ID:
          <br />
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.controllerid}
            onChange={(e) => handleInputChange("controllerid", e.target.value)}
          />
        </label>
        <br />

        <label>
          Title:
          <br />
          <input
           style={{width: "100%"}}
           type="text"
            value={controllerData.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
          />
        </label>
        <br />

        <label>
          ArtPaperSize:
          <br />
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.artpapersize}
            onChange={(e) => handleInputChange("artpapersize", e.target.value)}
          />
        </label>
        <br />

        <label>
          Dimensions:
          <br />
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.dimensions}
            onChange={(e) => handleInputChange("dimensions", e.target.value)}
          />
        </label>
        <br />

        <label>
          Description:
          <br />
          <input
           style={{width: "100%"}}
           type="text"
            value={controllerData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </label>
        <br />

        <label>
          Cost (comma-separated for multiple entries):
          <br />          
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.cost.join(", ")}
            onChange={(e) => handleInputChangeArray("cost", e.target.value)}
          />
        </label>
        <br />

        <label>
          Models (comma-separated for multiple entries):
          <br />          
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.models.join(", ")}
            onChange={(e) => handleInputChangeArray("models", e.target.value)}
          />
        </label>
        <br />
 
        <label>
          Comments:
          <br />          
          <input
           style={{width: "100%"}}
           type="text"
            value={controllerData.comments}
            onChange={(e) => handleInputChange("comments", e.target.value)}
          />
        </label>
        <br />

        <label>
          Powerports
          <br />          
          <input
            style={{width: "100%"}}
            type="number"
            value={controllerData.powerports}
            onChange={(e) => handleInputChange("powerports", e.target.value)}
          />
        </label>
        <br />

        <label>
          PowerPortType:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.powerporttype}
            onChange={(e) =>
              handleMultiSelectChange(
                "powerporttype",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">UBB-B to USB</option>
            <option value="2">USB-C to USB</option>
          </select>
        </label>
        <br />

        <label>
          Screen:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.screen}
           onChange={(e) => handleInputChange("screen", e.target.value)}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />

        <label>
          Passthroughport:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.passthroughport}
           onChange={(e) => handleInputChange("passthroughport", e.target.value)}         
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />

        <label>
         CustomArtTop:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.customarttop}
           onChange={(e) => handleInputChange("customarttop", e.target.value)}         
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />

        <label>
         customArtBottom:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.customartbottom}
           onChange={(e) => handleInputChange("customartbottom", e.target.value)}         
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />
{/* 
        <label>
          Size:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.size || []}
            onChange={(e) =>
              handleMultiSelectChange(
                "size",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">Mini</option>
            <option value="2">XS</option>
            <option value="3">Keyboard</option>
            <option value="4">S</option>
            <option value="5">M</option>
            <option value="6">L</option>
            <option value="7">XL</option>
            <option value="8">Box</option>
            <option value="9">Large Box</option>
          </select>
        </label>
        <br />

        <label>
          Controller Style:
          <br />
          <select
           style={{width: "100%"}}
           multiple
            value={controllerData.controllerstyle}
            onChange={(e) =>
              handleMultiSelectChange(
                "controllerstyle",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">Acrylic</option>
            <option value="2">Matte Acrylic</option>
            <option value="3">3D Printed</option>
            <option value="4">Split-Button</option>
            <option value="5">Ergonomic</option>
            <option value="6">Mini</option>
            <option value="7">Compact</option>
            <option value="8">Keyboard</option>
            <option value="9">Box</option>
          </select>
        </label>
        <br />


        <label>
          Keycaps:
          <br />
          <select
           style={{width: "100%"}}
           multiple
            value={controllerData.keycaps}
            onChange={(e) =>
              handleMultiSelectChange(
                "keycaps",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">20mm keycaps</option>
            <option value="2">20.2mm keycaps</option>
            <option value="3">25mm keycaps</option>
            <option value="4">25.3mm keycaps</option>
            <option value="5">22.5mm keycaps</option>
            <option value="6">24mm keycaps</option>
            <option value="7">28.5mm keycaps</option>
            <option value="8">30mm keycaps</option>
          </select>
        </label>
        <br /> 
*/}


<label>
  Size:
  <br />
  <select
    style={{width: "100%"}}
    multiple
    value={controllerData.size || []}
    onChange={(e) => handleMultiSelectChange("size", Array.from(e.target.selectedOptions, option => option.value))}
  >
    {sizeOptions.map((size, index) => (
      <option key={index} value={size.value}>{size.description}</option>
    ))}
  </select>
</label>
<br />

<label>
  Controller Style:
  <br />
  <select
    style={{width: "100%"}}
    multiple
    value={controllerData.controllerstyle}
    onChange={(e) => handleMultiSelectChange("controllerstyle", Array.from(e.target.selectedOptions, option => option.value))}
  >
    {controllerStyleOptions.map((style, index) => (
      <option key={index} value={style.controllerstyleid}>{style.description}</option>
    ))}
  </select>
</label>
<br />

<label>
  Keycaps:
  <br />
  <select
    style={{width: "100%"}}
    multiple
    value={controllerData.keycaps}
    onChange={(e) => handleMultiSelectChange("keycaps", Array.from(e.target.selectedOptions, option => option.value))}
  >
    {keycapsOptions.map((keycap, index) => (
      <option key={index} value={keycap.value}>{keycap.description}</option>
    ))}
  </select>
</label>
<br />


        <label>
          ButtonCountOptions:
          <br />
          <select
          style={{width: "100%"}}
            multiple
            value={controllerData.buttoncountoptions}
            onChange={(e) =>
              handleMultiSelectChange(
                "buttoncountoptions",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
          </select>
        </label>
        <br />

{/* 
        <label>
          Stock Switches:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.stockswitches}
            onChange={(e) =>
              handleMultiSelectChange(
                "stockswitches",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">Kailh v2 Red</option>
            <option value="2">Kailh v2 Haute42 Cosmox Crystal</option>
            <option value="3">Kailh v2 Deep Sea Islet Silent</option>
            <option value="4">Kailh v2 Haute42 Cosmox Wind Engine</option>
            <option value="5">Kailh v2 Ghost - Linear</option>
            <option value="6">Kailh v2 White Rain</option>
            <option value="7">Kailh v2 Spring Season</option>
            <option value="8">Kailh v2 Wizard - Clicky</option>
            <option value="9">Kailh v2 Phantom - Tactile</option>
            <option value="10">Kailh v1 Red</option>
            <option value="11">Kailh v1 Black</option>
            <option value="12">Kailh v1 Silver</option>
          </select>
        </label>
        <br />

        <label>
          Switches Upgrades I Recommend:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.switchesupgrades}
            onChange={(e) =>
              handleMultiSelectChange(
                "switchesupgrades",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">Kailh v2 Red</option>
            <option value="2">Kailh v2 Haute42 Cosmox Crystal</option>
            <option value="3">Kailh v2 Deep Sea Islet Silent</option>
            <option value="4">Kailh v2 Haute42 Cosmox Wind Engine</option>
            <option value="5">Kailh v2 Ghost - Linear</option>
            <option value="6">Kailh v2 White Rain</option>
            <option value="7">Kailh v2 Spring Season</option>
            <option value="8">Kailh v2 Wizard - Clicky</option>
            <option value="9">Kailh v2 Phantom - Tactile</option>
            <option value="10">Kailh v1 Red</option>
            <option value="11">Kailh v1 Black</option>
            <option value="12">Kailh v1 Silver</option>
          </select>
        </label>
        <br />
 */}

        <label>
          Stock Switches:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.stockswitches}
            onChange={(e) => handleMultiSelectChange("stockswitches", Array.from(e.target.selectedOptions, option => option.value))}
          >
            {switches.map(switchItem => (
              <option key={switchItem.switchid} value={switchItem.switchid}>{switchItem.description}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Switches Upgrades I Recommend:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.switchesupgrades}
            onChange={(e) => handleMultiSelectChange("switchesupgrades", Array.from(e.target.selectedOptions, option => option.value))}
          >
            {switches.map(switchItem => (
              <option key={switchItem.switchid} value={switchItem.switchid}>{switchItem.description}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          OS:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.os}
            onChange={(e) => handleMultiSelectChange(
              "os",
              Array.from(e.target.selectedOptions, option => option.value)
            )}
          >
            {osOptions.map(osItem => (
              <option key={osItem.osid} value={osItem.osid}>
                {osItem.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Play Location:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.playlocation}
            onChange={(e) => handleMultiSelectChange(
              "playlocation",
              Array.from(e.target.selectedOptions, option => option.value)
            )}
          >
            {playLocationOptions.map(location => (
              <option key={location.playlocationid} value={location.playlocationid}>
                {location.name}
              </option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Adapters:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.adapters}
            onChange={(e) => handleMultiSelectChange("adapters", Array.from(e.target.selectedOptions, (option) => option.value))}
          >
            {adaptersOptions.map((adapter, index) => (
              <option key={index} value={adapter.adapterid}>{adapter.name}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Accessories:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.accessories}
            onChange={(e) => handleMultiSelectChange("accessories", Array.from(e.target.selectedOptions, (option) => option.value))}
          >
            {accessoriesOptions.map((accessory, index) => (
              <option key={index} value={accessory.accessoriesid}>{accessory.name}</option>
            ))}
          </select>
        </label>
        <br />


        <br />
        <br />
        <label>
          Sellers:
          <br />
          {controllerData.sellers.map((seller, index) => (
            <div key={index}>
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Seller Name"
                value={seller.name}
                onChange={(e) => {
                  const updatedSellers = [...controllerData.sellers];
                  updatedSellers[index].name = e.target.value;
                  setControllerData({ ...controllerData, sellers: updatedSellers });
                }}
              />
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Seller Link"
                value={seller.link}
                onChange={(e) => {
                  const updatedSellers = [...controllerData.sellers];
                  updatedSellers[index].link = e.target.value;
                  setControllerData({ ...controllerData, sellers: updatedSellers });
                }}
              />
            </div>
          ))}
          <button 
            type="button" 
            onClick={handleAddSeller}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}  
          >
            Add Seller
          </button>
        </label>
        <br />

        <br />
        <label>
          Images:
          <br />
          {controllerData.images.map((image, index) => (
            <div key={index}>
              <input
                style={{width: "100%"}}
                type="text"
                placeholder="Image Link"
                value={image.link}
                onChange={(e) => {
                  const updatedImages = [...controllerData.images];
                  updatedImages[index].link = e.target.value;
                  setControllerData({ ...controllerData, images: updatedImages });
                }}
              />
            </div>
          ))}
          <button 
            type="button" 
            onClick={handleAddImage}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add Image
          </button>
        </label>
        <br />



        <br />
        <label>
          Support Links:
          <br />
          {controllerData.supportlinks.map((supportlink, index) => (
            <div key={index}>
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Support Link URL"
                value={supportlink.link}
                onChange={(e) => {
                  const updatedSupportLinks = [...controllerData.supportlinks];
                  updatedSupportLinks[index].link = e.target.value;
                  setControllerData({ ...controllerData, supportlinks: updatedSupportLinks });
                }}
              />
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Notes"
                value={supportlink.notes}
                onChange={(e) => {
                  const updatedSupportLinks = [...controllerData.supportlinks];
                  updatedSupportLinks[index].notes = e.target.value;
                  setControllerData({ ...controllerData, supportlinks: updatedSupportLinks });
                }}
              />
            </div>
          ))}
          <button 
            type="button" 
            onClick={handleAddSupportLink}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add Support Link
          </button>
        </label>
        <br />



        <br />
        <label>
          Similar Controller:
          <br />

{controllerData.similarcontroller.map((item, index) => (
        <div key={index}>
          <select
            value={item.brandid}
            onChange={e => handleChangeSimilarController(index, 'brandid', e.target.value)}
          >
            <option value="">Select Brand</option>
            {brands.map(brand => (
              <option key={brand.brandid} value={brand.brandid}>{brand.brandname}</option>
            ))}
          </select>

          <select
            value={item.controllerid}
            onChange={e => handleChangeSimilarController(index, 'controllerid', e.target.value)}
            disabled={!item.brandid}
          >
            <option value="">Select Controller</option>
            {/* {item.brandid && renderControllerOptions2a(item.brandid)} */}
            {/* {item.brandid && renderControllerOptions2b(item.brandid)} */}
            {item.brandid && renderControllerOptions2c(item.brandid)}
            {/* {item.brandid && renderControllerOptions(item.brandid)} */}

          </select>

          <input
            type="text"
            placeholder="Comments"
            value={item.comments}
            onChange={e => handleChangeSimilarController(index, 'comments', e.target.value)}
          />

          <select
            value={item.similarfingerspacing}
            onChange={e => handleChangeSimilarController(index, 'similarfingerspacing', e.target.value)}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>

          <br />




        </div>
      ))}

      <button 
        type="button" 
        onClick={handleAddSimilarController}
        style={{
          marginTop: "10px",
          padding: "10px",
          backgroundColor: "#007BFF",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Add Similar Controller
      </button>
</label>




        <br />
        <br />
        <br />
        <button 
          type="button"
          onClick={handleSave}
          
          style={{
            marginTop: "10px",
            padding: "10px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Save
        </button>
      </form>








      <br />
      <hr />
      <br />
      

      
      <h2 style={{ marginTop: 0 }}>Loaded JSON Data:</h2>
      {jsonData && (
        <div
          style={{
            flex: "0 0 auto",
            backgroundColor: "#f8f9fa",
            padding: "10px",
            borderTop: "1px solid #ddd",
            overflowY: "auto",
            maxHeight: "200px",
          }}
        >
          <pre style={{ margin: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
        </div>
      )} 
       



      {jsonData ? (
        <div>
          <h2>Loaded Data:</h2>
          <button
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleCopyToClipboard}
          >
            Copy Data List to Clipboard
          </button>
          <pre style={{ margin: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        </div>
      ) : (
        <p>Loading data...</p>
      )}

      

      
      
    </div>
  );
};

export default AddControllerForm;
