import React, { useState, useEffect } from 'react';
import data from '../data/data-v2.json';
import ResultCard from './ResultCard';

const ButtonCountSelection = () => {
  const [buttonCounts, setButtonCounts] = useState([]);
  const [selectedButtonCounts, setSelectedButtonCounts] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    // Extract unique button counts from the JSON
    const counts = Array.from(
      new Set(
        data.brands.flatMap((brand) =>
          brand.controllers.flatMap((controller) => controller.buttoncountoptions)
        )
      )
    ).sort((a, b) => a - b); // Sort numerically
    setButtonCounts(counts);
  }, []);

  const handleFilter = () => {
    if (selectedButtonCounts.length === 0) return;

    const filteredControllers = data.brands.map((brand) => {
      const controllersWithButtonCounts = brand.controllers.filter((controller) =>
        controller.buttoncountoptions.some((count) =>
          selectedButtonCounts.includes(count)
        )
      );

      if (controllersWithButtonCounts.length > 0) {
        return {
          brandName: brand.brandname,
          controllers: controllersWithButtonCounts.map((controller) => ({
            name: controller.title,
            description: controller.description,
            sizes: controller.size.map(
              (sizeId) =>
                data.size.find((size) => size.sizeid === parseInt(sizeId))?.name
            ),
            playLocations: controller.playlocation.map(
              (locationId) =>
                data.playlocation.find((loc) => loc.playlocationid === parseInt(locationId))?.name
            ),
            buttons: controller.buttoncountoptions,
            costs: controller.cost,
            controllerstyle: controller.controllerstyle,
            sellers: controller.sellers,
          })),
        };
      }
      return null;
    }).filter(Boolean);

    setFilteredResults(filteredControllers);
  };

  const handleButtonCountChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(parseInt(options[i].value));
      }
    }
    setSelectedButtonCounts(selected);
  };

  return (
    <div className="button-count-selection">
      <h4 className="title">Button Count Selection</h4>

      <div className="filter-container">
        <label htmlFor="button-count-filter">Select Button Counts:</label>
        <br />
        <select
          id="button-count-filter"
          multiple
          value={selectedButtonCounts}
          onChange={handleButtonCountChange}
          className="multi-select"
        >
          {buttonCounts.map((count) => (
            <option key={count} value={count}>
              {count} Buttons
            </option>
          ))}
        </select>
        <br />
        <button onClick={handleFilter} className="filter-button">
          Filter
        </button>
      </div>

      <div className="results-container">
        {filteredResults.length > 0 ? (
          filteredResults.map((result, index) => (
            <ResultCard key={index} brand={result.brandName} controllers={result.controllers} />
          ))
        ) : (
          <p className="no-results">No results found. Please select button counts to filter.</p>
        )}
      </div>
    </div>
  );
};

export default ButtonCountSelection;
