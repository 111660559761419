import React from "react";
import { Link } from "react-router-dom";
import "../pagestyles/AppPage.scss";
import Footer from "../components/Footer";

const AppPage = () => {
  return (
    <div className="app-page">
      <h1>Welcome to the Controller Selector</h1>
      <p>How would you like to search?</p>
      <div className="button-container">
        <Link to="/controller-style-selection" className="large-button">
          Search By Controller Style
        </Link>
        <Link to="/switch-selection" className="large-button">
          Search By Switches
        </Link>
        <Link to="/adapter-selection" className="large-button">
          Search By Adapter
        </Link>
        <Link to="/size-selection" className="large-button">
          Search By Size
        </Link>
        <Link to="/playlocation-selection" className="large-button">
          Search By Play Location
        </Link>
        <Link to="/accessories-selection" className="large-button">
          Search By Accessories
        </Link>
        <Link to="/button-count-selection" className="large-button">
          Search By Button Count
        </Link>
        <Link to="/brand-selection" className="large-button">
          Search By Brand
        </Link>
        {/* <Link to="/add-controller" className="large-button">
          Add a Controller
        </Link> */}
      </div>
      <Footer />
    </div>
  );
};

export default AppPage;
