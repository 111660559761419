import React, { useState, useEffect } from 'react';
import data from '../data/data-v2.json';
import ResultCard from './ResultCard';

const AdapterSelection = () => {
    const [adapters, setAdapters] = useState([]);
    const [selectedAdapters, setSelectedAdapters] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        setAdapters(data.adapters); // Load adapters from JSON
    }, []);

    const handleFilter = () => {
        const selectedAdapterIds = selectedAdapters.map(Number);

        const filteredControllers = data.brands.map((brand) => {
            const controllersWithAdapters = brand.controllers.filter((controller) =>
                controller.adapters.some((adapter) => selectedAdapterIds.includes(Number(adapter)))
            );

            if (controllersWithAdapters.length > 0) {
                return {
                    brandName: brand.brandname,
                    controllers: controllersWithAdapters.map((controller) => ({
                        name: controller.title,
                        description: controller.description,
                        styles: controller.controllerstyle.map(
                            (styleId) =>
                                data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId)).description
                        ),
                        sellers: controller.sellers,
                    })),
                };
            }
            return null;
        }).filter(Boolean);

        setFilteredResults(filteredControllers);
    };

    const handleAdapterChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(options[i].value);
            }
        }
        setSelectedAdapters(selected);
    };

    return (
        <div className="adapter-selection">
            <h4 className="title">Adapter Selection</h4>

            <div className="filter-container">
                <label htmlFor="adapter-filter">Select Adapters:</label>
                <br />
                <select
                    id="adapter-filter"
                    multiple
                    value={selectedAdapters}
                    onChange={handleAdapterChange}
                    className="multi-select"
                >
                    {adapters.map((adapter) => (
                        <option key={adapter.adaptersid} value={adapter.adaptersid}>
                            {adapter.name}
                        </option>
                    ))}
                </select>
                <br />
                <button onClick={handleFilter} className="filter-button">
                    Filter
                </button>
            </div>

            <div className="results-container">
                {filteredResults.length > 0 && (
                    <div className="result-group">
                        <h5>Controllers with Selected Adapters</h5>
                        {filteredResults.map((result, index) => (
                            <ResultCard key={index} brand={result.brandName} controllers={result.controllers} />
                        ))}
                    </div>
                )}

                {filteredResults.length === 0 && (
                    <p className="no-results">No results found. Please select adapters to filter.</p>
                )}
            </div>

            <hr />

            <h4>All Available Adapters</h4>
            <ul>
                {adapters.map((adapter) => (
                    <li key={adapter.adaptersid}>
                        <strong>{adapter.name}</strong>: {adapter.description}
                        {adapter.link ? (
                            <p>
                                <a href={adapter.link} target="_blank" rel="noopener noreferrer">
                                    Buy Here
                                </a>
                            </p>
                        ) : (
                            <p>No link available</p>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdapterSelection;
