import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v2.json';

const SizeSelection = () => {
    const [sizes, setSizes] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        setSizes(data.size); // Load sizes from JSON
    }, []);

    const handleFilter = () => {
        const results = filterControllers(selectedSizes, 'size');
        setFilteredResults(results);
    };

    const handleSizeChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(options[i].value);
            }
        }
        setSelectedSizes(selected);
    };

    return (
        <div className="size-selection">
            <h4 className="title">Size Selection</h4>

            <div className="filter-container">
                <label htmlFor="size-filter">Select Sizes:</label>
                <br />
                <select
                    id="size-filter"
                    multiple
                    value={selectedSizes}
                    onChange={handleSizeChange}
                    className="multi-select"
                >
                    {sizes.map((size) => (
                        <option key={size.sizeid} value={size.sizeid}>
                            {size.name}
                        </option>
                    ))}
                </select>
                <br />
                <button onClick={handleFilter} className="filter-button">
                    Filter
                </button>
            </div>

            <div className="results-container">
                {filteredResults.length > 0 ? (
                    filteredResults.map((result, index) => (
                        <ResultCard key={index} brand={result.brandName} controllers={result.controllers} />
                    ))
                ) : (
                    <p className="no-results">No results found. Please select sizes to filter.</p>
                )}
            </div>
        </div>
    );
};

export default SizeSelection;
