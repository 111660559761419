import data from '../data/data-v2.json';

export const filterControllers = (selectedIds, filterKey) => {
    return data.brands
        .map((brand) => {
            const filteredControllers = brand.controllers.filter((controller) =>
                selectedIds.every((id) => controller[filterKey].includes(id.toString()))
            );

            if (filteredControllers.length > 0) {
                return {
                    brandName: brand.brandname,
                    controllers: filteredControllers.map((controller) => ({
                        name: controller.title,
                        description: controller.description,
                        sizes: controller.size.map(
                            (sizeId) =>
                                data.size.find((size) => size.sizeid === parseInt(sizeId))?.name
                        ),
                        controllerstyle: controller.controllerstyle.map(
                            (styleId) =>
                                data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId))?.description
                        ),
                        playLocations: controller.playlocation.map(
                            (locationId) =>
                                data.playlocation.find((location) => location.playlocationid === parseInt(locationId))?.name
                        ),
                        sellers: controller.sellers,
                    })),
                };
            }
            return null;
        })
        .filter(Boolean);
};




// import data from '../data/data-v2.json';

// export const filterControllers = (selectedIds, filterKey) => {
//     return data.brands
//         .map((brand) => {
//             const filteredControllers = brand.controllers.filter((controller) =>
//                 selectedIds.every((id) => controller[filterKey].includes(id.toString()))
//             );

//             if (filteredControllers.length > 0) {
//                 return {
//                     brandName: brand.brandname,
//                     controllers: filteredControllers.map((controller) => ({
//                         name: controller.title,
//                         description: controller.description,
//                         sizes: controller.size.map(
//                             (sizeId) =>
//                                 data.size.find((size) => size.sizeid === parseInt(sizeId))?.name
//                         ),
//                         controllerstyle: controller.controllerstyle.map(
//                             (styleId) =>
//                                 data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId))?.description
//                         ),
//                         playLocations: controller.playlocation.map(
//                             (locationId) =>
//                                 data.playlocation.find((location) => location.playlocationid === parseInt(locationId))?.name
//                         ),
//                         sellers: controller.sellers,
//                     })),
//                 };
//             }
//             return null;
//         })
//         .filter(Boolean);
// };





// import data from '../data/data-v2.json';

// export const filterControllers = (filterCriteria, key) => {
//     const selectedIds = filterCriteria.map(Number);

//     return data.brands.map((brand) => {
//         const filteredControllers = brand.controllers.filter((controller) =>
//             controller[key].some((item) => selectedIds.includes(Number(item)))
//         );

//         if (filteredControllers.length > 0) {
//             return {
//                 brandName: brand.brandname,
//                 controllers: filteredControllers.map((controller) => ({
//                     name: controller.title,
//                     description: controller.description,
//                     sizes: controller.size.map(
//                         (sizeId) =>
//                             data.size.find((size) => size.sizeid === parseInt(sizeId)).name
//                     ),
//                     styles: controller.controllerstyle.map(
//                         (styleId) =>
//                             data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId)).description
//                     ),
//                     playLocations: controller.playlocation?.map(
//                         (locationId) =>
//                             data.playlocation.find((location) => location.playlocationid === parseInt(locationId))?.name
//                     ),
//                     adapters: controller.adapters?.map(
//                         (adapterId) =>
//                             data.adapters.find((adapter) => adapter.adaptersid === parseInt(adapterId))?.name
//                     ),
//                     sellers: controller.sellers,
//                 })),
//             };
//         }
//         return null;
//     }).filter(Boolean);
// };
