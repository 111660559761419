import React from 'react';
import '../componentstyles/Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        <strong>Affiliate Link Disclosure:</strong> 
        Links are affiliate links. 
        If you make a purchase through these links, I may earn a small commission AT NO ADDITIONAL COST TO YOU.  
        The vendor pays a referral to the channel and helps fund the channel and this site AT NO COST TO YOU. 
        Thank you for supporting the channel!
      </p>
    </footer>
  );
};

export default Footer;
