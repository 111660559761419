import React from 'react';
import ButtonCountSelection from '../components/ButtonCountSelection';
import '../pagestyles/ButtonCountSelectionPage.scss';
import Footer from '../components/Footer';

const ButtonCountSelectionPage = () => {
  return (
    <div className="button-count-selection-page">
      <ButtonCountSelection />
      <Footer />
    </div>
  );
};

export default ButtonCountSelectionPage;
