import React from 'react';
import PlayLocationSelection from '../components/PlayLocationSelection';
import '../pagestyles/PlayLocationSelectionPage.scss';
import Footer from '../components/Footer';

const PlayLocationSelectionPage = () => {
  return (
    <div className="playlocation-selection-page">
      <PlayLocationSelection />
      <Footer />
    </div>
  );
};

export default PlayLocationSelectionPage;
