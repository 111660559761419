import React, { useState, useEffect } from 'react';
import data from '../data/data-v2.json';
import ResultCard from './ResultCard';

const SwitchSelection = () => {
  const [switches, setSwitches] = useState([]);
  const [selectedSwitches, setSelectedSwitches] = useState([]);
  const [stockResults, setStockResults] = useState([]);
  const [upgradeResults, setUpgradeResults] = useState([]);

  useEffect(() => {
    setSwitches(data.switches); // Load switches from JSON
  }, []);


  const handleFilter = () => {
    // Get selected switch IDs as integers
    const selectedSwitchIds = selectedSwitches.map(Number);

    // Filter controllers based on stock switches
    const stockFilteredResults = data.brands.map((brand) => {
        const filteredControllers = brand.controllers.filter((controller) =>
            controller.stockswitches.some((stockSwitch) => selectedSwitchIds.includes(Number(stockSwitch)))
        );

        if (filteredControllers.length > 0) {
            return {
                brandName: brand.brandname,
                controllers: filteredControllers.map((controller) => ({
                    name: controller.title,
                    description: controller.description,
                    styles: controller.controllerstyle.map(
                        (styleId) => data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId)).description
                    ),
                    sellers: controller.sellers,
            })),
            };
        }
        return null;
    }).filter(Boolean);

    // Filter controllers based on switch upgrades
    const upgradeFilteredResults = data.brands.map((brand) => {
        const filteredControllers = brand.controllers.filter((controller) =>
            controller.switchesupgrades.some((upgradeSwitch) => selectedSwitchIds.includes(Number(upgradeSwitch)))
        );

        if (filteredControllers.length > 0) {
            return {
                brandName: brand.brandname,
                controllers: filteredControllers.map((controller) => ({
                    name: controller.title,
                    description: controller.description,
                    styles: controller.controllerstyle.map(
                        (styleId) => data.controllerstyle.find((style) => style.controllerstyleid === parseInt(styleId)).description
                    ),
                    sellers: controller.sellers,
            })),
            };
        }
        return null;
    }).filter(Boolean);

    setStockResults(stockFilteredResults);
    setUpgradeResults(upgradeFilteredResults);
};

const handleSwitchChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
            selected.push(options[i].value);
        }
    }
    setSelectedSwitches(selected);
};

  return (
    <div className="switch-selection">

<h4 className="title">Switch Selection</h4>

<div className="filter-container">
    <label htmlFor="switch-filter">Select Switches:</label>
    <br />
    <select
        id="switch-filter"
        multiple
        value={selectedSwitches}
        onChange={handleSwitchChange}
        className="multi-select"
    >
        {switches.map((switchItem) => (
            <option key={switchItem.switchid} value={switchItem.switchid}>
                {switchItem.description}
            </option>
        ))}
    </select>
    <br />
    <button onClick={handleFilter} className="filter-button">
        Filter
    </button>
</div>

<div className="results-container">
    {stockResults.length > 0 && (
        <div className="result-group">
            <h5>Stock Switches</h5>
            {stockResults.map((result, index) => (
                <ResultCard key={`stock-${index}`} brand={result.brandName} controllers={result.controllers} />
            ))}
        </div>
    )}

    {upgradeResults.length > 0 && (
        <div className="result-group">
            <h5>Switch Upgrade Options</h5>
            {upgradeResults.map((result, index) => (
                <ResultCard key={`upgrade-${index}`} brand={result.brandName} controllers={result.controllers} />
            ))}
        </div>
    )}

    {stockResults.length === 0 && upgradeResults.length === 0 && (
        <p className="no-results">No results found. Please select switches to filter.</p>
    )}
</div>

<hr />
<hr />
<hr />
<hr />

      <h4>Switch Options</h4>
      <ul>
        {switches.map((switchItem, index) => (
          <li key={index}>
            <strong>{switchItem.description}</strong>: {switchItem.notes}
            <p>
              <strong>Styles:</strong> {switchItem.styles.join(', ')}
            </p>
            {switchItem.links.map((link, linkIndex) => (
              <p key={linkIndex}>
                <a href={link.amazon || link.aliexpress} target="_blank" rel="noopener noreferrer">
                  {link.amazon ? 'Buy on Amazon' : 'Buy on AliExpress'}
                </a>
              </p>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SwitchSelection;
