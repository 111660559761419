import React from 'react';
import SizeSelection from '../components/SizeSelection';
import '../pagestyles/SizeSelectionPage.scss';
import Footer from '../components/Footer';

const SizeSelectionPage = () => {
    return (
        <div className="size-selection-page">
            <SizeSelection />
            <Footer />
        </div>
    );
};

export default SizeSelectionPage;
